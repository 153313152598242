import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector, useDispatch } from 'react-redux';
import Header from 'Components/Header/Header';
import { getMenus } from 'Chores/request';
import GP_LOGO from '../../Public/Image/Logo.png';
import { saveToken } from 'Slices/App/appSlice';
import { addMenus, addLoginUserId } from 'Slices/commonSlice';
import { VegaFlex, VegaLeftNavGroup, VegaLeftNavLink, VegaInput, VegaLeftNav, VegaBox } from '@heartlandone/vega-react';
import {
  pathReplaceHandle,
  getMenuIcon,
  filterMenuTree,
  setAllUserAvatar,
  setAllDictType,
  setUserPermissions,
  setFromlocalStorage,
  getFromlocalStorage
} from 'Chores/utils';
import { FE_ROUTES } from 'Chores/Api';
document.body.id = 'body-id';

const Layout = () => {
  const menus = useSelector((state) => state.common.menus);
  const token = useSelector((state) => state.app.token);
  const [originMenu, setOriginMenu] = useState([]);
  const [isShowMenuSearch, setMenuSearchShown] = useState(true);
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const location = useLocation();

  useLayoutEffect(() => {
    document.querySelector('vega-left-nav').open = true;
  }, []);

  useEffect(() => {
    if (keycloak.authenticated) {
      sessionStorage.setItem('token', `${keycloak.token}`);
      dispatch(saveToken(keycloak.token));
      dispatch(addLoginUserId(keycloak.idTokenParsed.sub));

      setAllUserAvatar();
      setAllDictType();
      setUserPermissions(keycloak.idTokenParsed.sub);
    } else {
      setFromlocalStorage('loginRedirect', location, 20);
      navigate('/');
    }
  }, [keycloak.authenticated]);

  useEffect(() => {
    if (token) {
      const loadId = VegaLoader.load({
        containerSelector: '#body-id'
      });
      getMenus().then((response) => {
        const result = getMenuIcon(pathReplaceHandle(response));
        dispatch(addMenus(result));
        setOriginMenu(result);
        VegaLoader.close(loadId);
        const loginRedirect = getFromlocalStorage('loginRedirect');
        if (loginRedirect) {
          navigate(loginRedirect.pathname + loginRedirect.search);
        }
      });
    }
  }, [token]);

  const renderMenuData = (data = []) => {
    return (
      <>
        {data.map((item) => {
          if (item?.children?.length) {
            return (
              <VegaLeftNavGroup icon={item.icon} key={item.key} label={item.label}>
                {renderMenuData(item.children)}
              </VegaLeftNavGroup>
            );
          } else if (item?.nodeType === 3) {
            return (
              <VegaLeftNavLink
                selected={location.pathname.replace(FE_ROUTES.HOME, '') === item.newPath}
                icon={item.icon}
                onVegaMenuClick={() => {
                  if (item?.extraProperties?.component === 'poker') {
                    window.open('https://poker.gprddigital.com');
                  } else if (item?.extraProperties?.component === 'activityhub') {
                    const token = sessionStorage.getItem('token');
                    if (!token) {
                      window.alert('Error, please re-login');
                      return false;
                    }

                    window.open(`${process.env.REACT_APP_ACTIVITY_HUB}?token=${token}`);
                  } else {
                    navigate(`${FE_ROUTES.HOME}${item.newPath}`);
                    localStorage.setItem('_project', item.extraProperties?.projectId);
                  }
                }}
                key={item.key}
              >
                {item.label}
              </VegaLeftNavLink>
            );
          }
        })}
      </>
    );
  };

  const handleMenuSearch = (e) => {
    const res = filterMenuTree(e.target.value, originMenu);
    dispatch(addMenus(res));
  };
  return (
    <VegaBox className="App">
      <VegaLeftNav
        footnote={`Version 3.0.0`}
        showAsOverlay={false}
        onVegaStateUpdate={(e) => {
          setMenuSearchShown(e.detail === 'close' ? false : true);
        }}
      >
        <div slot="header-slot">
          <img src={GP_LOGO} style={{ width: '100%' }}></img>
        </div>

        <div className={isShowMenuSearch ? '' : 'v-hidden'}>
          <VegaInput
            id="menuSearch"
            autoComplete="off"
            prefixIcon="search"
            showClearIcon="true"
            size="small"
            placeholder="Search"
            onVegaChange={(e) => handleMenuSearch(e)}
          ></VegaInput>
        </div>
        {renderMenuData(menus)}
      </VegaLeftNav>
      <VegaBox padding="size-32" className="vega-left-nav-content">
        <VegaFlex direction="col" gap="size-24" style={{ height: '100%' }}>
          <Header />
          <Outlet />
          {/* wait vega components update */}
          {/* <VegaAppFooter
            tosUrl="https://www.heartland.us/terms"
            privacyUrl="https://www.heartland.us/privacy-policy"
            showDisclaimer={false}
          ></VegaAppFooter> */}
        </VegaFlex>
      </VegaBox>
    </VegaBox>
  );
};
export default Layout;
